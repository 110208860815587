function showStages(stages, totalStages, start, end) {
  for (let i = 0; i < totalStages; i++) {
    if (i >= start && i < end) {
      stages[i].classList.remove("p-hidden");
    } else {
      stages[i].classList.add("p-hidden");
    }
  }
}

function handleResize(
  stagesContainer,
  stages,
  totalStages,
  showAllStages,
  initialVisibleStages,
  btnMoreStages,
  btnLessStages
) {
  const otherWaysDiv = document.getElementById("otherWays");

  if (window.innerWidth < 768) {
    stagesContainer.classList.add(
      "p-flex",
      "p-flex-nowrap",
      "p-space-x-4",
      "p-overflow-x-auto",
      "scrollbar-thin"
    );
    stagesContainer.classList.remove(
      "md:p-grid",
      "md:p-grid-cols-4",
      "md:p-gap-6",
      "md:p-space-x-0",
      "md:p-overflow-hidden"
    );

    showStages(stages, totalStages, 0, totalStages);
    btnMoreStages.parentElement.classList.add("p-hidden");

    if (otherWaysDiv) {
      otherWaysDiv.classList.add("p-hidden");
    }
  } else {
    stagesContainer.classList.remove(
      "p-flex",
      "p-flex-nowrap",
      "p-space-x-4",
      "p-overflow-x-auto",
      "scrollbar-thin"
    );
    stagesContainer.classList.add(
      "md:p-grid",
      "md:p-grid-cols-4",
      "md:p-gap-6",
      "md:p-space-x-0",
      "md:p-overflow-hidden"
    );

    if (otherWaysDiv) {
      otherWaysDiv.classList.remove("p-hidden");
    }

    showStages(
      stages,
      totalStages,
      0,
      showAllStages ? totalStages : initialVisibleStages
    );

    if (showAllStages) {
      btnMoreStages.classList.add("p-hidden");
      btnLessStages.classList.remove("p-hidden");
    } else {
      btnMoreStages.classList.remove("p-hidden");
      btnLessStages.classList.add("p-hidden");
    }
    btnMoreStages.parentElement.classList.remove("p-hidden");
  }
}

const stagesContainer = document.getElementById("stagesContainer");

if (stagesContainer && document.getElementById("showMoreStages")) {
  const btnMoreStages = document.getElementById("btnMoreStages");
  const btnLessStages = document.getElementById("btnLessStages");

  const stages = stagesContainer.children;
  const totalStages = stages.length;
  const initialVisibleStages = 12;
  let showAllStages = false;

  btnMoreStages.addEventListener("click", () => {
    showAllStages = true;
    showStages(stages, totalStages, 0, totalStages);

    btnMoreStages.classList.add("p-hidden");
    btnLessStages.classList.remove("p-hidden");
  });

  btnLessStages.addEventListener("click", () => {
    showAllStages = false;
    showStages(stages, totalStages, 0, initialVisibleStages);

    btnMoreStages.classList.remove("p-hidden");
    btnLessStages.classList.add("p-hidden");
  });

  window.addEventListener("resize", () =>
    handleResize(
      stagesContainer,
      stages,
      totalStages,
      showAllStages,
      initialVisibleStages,
      btnMoreStages,
      btnLessStages
    )
  );

  // Inicialización en el primer renderizado
  handleResize(
    stagesContainer,
    stages,
    totalStages,
    showAllStages,
    initialVisibleStages,
    btnMoreStages,
    btnLessStages
  );
}
