// import external dependencies
import 'jquery';

// import template modules
import "./modules/webphone";
import "./modules/downloadGuide.js";

// Import everything from autoload
import "./autoload/_scrolling.js";
import "./autoload/sidebarPlanner.js";

// Import everything from tailwind
import "./tailwind/global/_accordion_faqs.js"; import "./tailwind/global/_accordion_see_more.js"; import "./tailwind/global/_carousel_experiences.js"; import "./tailwind/global/_carousel_opinions.js"; import "./tailwind/global/_data-background.js"; import "./tailwind/global/_modal.js"; import "./tailwind/global/_nav_tab_info.js"; import "./tailwind/global/_nav_tab_interest.js"; import "./tailwind/global/_plannig_tips.js"; import "./tailwind/global/_slider_what_way_home.js"
import "./tailwind/stage/_nav_tab_stage.js"; import "./tailwind/stage/_nav_tab_variants.js"; import "./tailwind/stage/_nav-tab-scrollbar.js"
import "./tailwind/ways/_stages_position.js"
import "./tailwind/header/_header.js";
import "./tailwind/blog/_see_more_post.js"; import "./tailwind/blog/_selector.js";
import "./tailwind/search/_search-faqs.js"; import "./tailwind/search/catch_search.js";

// Import js for experience with tailwind
import "./experience/_accordion_and_scroll.js"; import "./experience/_filter_home.js"; import "./experience/_slider_experiences.js"; import "./experience/_slider_opinions.js"; import "./experience/_tab.js"

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
