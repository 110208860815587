document.addEventListener("DOMContentLoaded", function () {
  const faqQuestions = document.querySelectorAll(".faqsQuestion__title");
  let activeQuestion = null;

  faqQuestions.forEach((question) => {
    question.addEventListener("click", function () {
      const content = this.nextElementSibling;
      const symbolSpan = this.querySelector("span");
      const isExpanded = content.classList.contains("active");

      if (isExpanded) {
        this.classList.remove("active");
        content.classList.remove("active");
        symbolSpan.textContent = "+";
        activeQuestion = null;
      } else {
        if (activeQuestion) {
          const activeContent = activeQuestion.nextElementSibling;
          const activeSymbol = activeQuestion.querySelector("span");

          activeQuestion.classList.remove("active");
          activeContent.classList.remove("active");
          activeSymbol.textContent = "+";
        }

        this.classList.add("active");
        content.classList.add("active");
        symbolSpan.textContent = "-";
        activeQuestion = this;
      }
    });
  });
});
