document.addEventListener("DOMContentLoaded", function () {
    function toggleSection(section) {
        const sections = [
            "mochila", "dinero", "documentos", "camino", "ruta", "hacer",
            "primitivo", "frances", "norte", "portugues", "ingles",
            "salud", "ayuda", "cuestiones", "catedral", "bicis",
        ];
        let target = document.getElementById(section);
        let icon = document.getElementById("icon-" + section);

        if (!target) return;

        if (target.classList.contains("p-expanded")) {
            target.style.maxHeight = "0px";
            target.classList.remove("p-expanded");
            icon.textContent = "+";
            return;
        }

        sections.forEach(s => {
            let other = document.getElementById(s);
            let otherIcon = document.getElementById("icon-" + s);
            if (other) {
                other.style.maxHeight = "0px";
                other.classList.remove("p-expanded");
            }
            if (otherIcon) {
                otherIcon.textContent = "+";
            }
        });

        target.style.maxHeight = target.scrollHeight + "px";
        target.classList.add("p-expanded");
        icon.textContent = "-";

        let firstQuestion = target.querySelector(".p-question");
        if (firstQuestion) {
            let firstQuestionId = firstQuestion.getAttribute("onclick").match(/'([^']+)'/)[1];
            showContent(null, firstQuestionId);
        }
    }

    function showContent(event, contentId) {
        document.querySelectorAll(".p-content").forEach(el => el.classList.add("p-hidden"));

        let contentToShow = document.getElementById(contentId);
        if (contentToShow) {
            contentToShow.classList.remove("p-hidden");
        }

        document.querySelectorAll(".p-question").forEach(el => {
            el.classList.remove("p-text-primary", "p-underline");
            el.classList.add("p-text-gray");
        });

        if (event) {
            event.target.classList.add("p-text-primary", "p-underline");
            event.target.classList.remove("p-text-gray");
        } else {
            let selectedQuestion = document.querySelector(`[onclick="showContent(event, '${contentId}')"]`);
            if (selectedQuestion) {
                selectedQuestion.classList.add("p-text-primary", "p-underline");
                selectedQuestion.classList.remove("p-text-gray");
            }
        }
    }

    document.addEventListener("DOMContentLoaded", () => {
        let defaultSection = document.getElementById("mochila") || document.getElementById("salud"); // Prioriza mochila, pero usa salud si no existe
        if (defaultSection) {
            defaultSection.style.maxHeight = defaultSection.scrollHeight + "px";
            defaultSection.classList.add("p-expanded");
            let defaultIcon = document.getElementById("icon-" + defaultSection.id);
            if (defaultIcon) defaultIcon.textContent = "-";
        }
        showContent(null, "mochila-1");
    });

    window.toggleSection = toggleSection;
    window.showContent = showContent;
});