document.addEventListener("DOMContentLoaded", function () {
  let currentStage = document.querySelector(".stage-list__stage-card--primary");
  let stageContainer = document.querySelector(".stage-list");
  if (currentStage && stageContainer) {
    let elementPosition = currentStage.getBoundingClientRect();
    let containerPosition = stageContainer.getBoundingClientRect();

    let isVisible =
      elementPosition.right <= containerPosition.right &&
      elementPosition.left >= containerPosition.left;

    if (!isVisible) {
      stageContainer.scroll({
        left:
          currentStage.offsetLeft -
          containerPosition.width / 2 +
          elementPosition.width / 2,
        behavior: "smooth",
      });
    }
  }
});
