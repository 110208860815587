// Slider para Opiniones
const initSliderOpinions = () => {
    const imageList = document.querySelector(".slider-wrapper-op .card-list-op");
    const slideButtons = document.querySelectorAll(
      ".slider-wrapper-op .slide-button-op"
    );
    const sliderScrollbar = document.querySelector(
      ".container-slider-op .slider-scrollbar-op"
    );
    const indicators = document.querySelectorAll(
      ".slider-indicators-op .indicator-op"
    );
    if (sliderScrollbar && imageList && slideButtons) {
      const scrollbarThumb = sliderScrollbar.querySelector(".scrollbar-thumb");
  
      const maxScrollLeft = imageList.scrollWidth - imageList.clientWidth;
  
      scrollbarThumb.addEventListener("mousedown", (e) => {
        const startX = e.clientX;
        const thumbPosition = scrollbarThumb.offsetLeft;
        const maxThumbPosition =
          sliderScrollbar.getBoundingClientRect().width -
          scrollbarThumb.offsetWidth;
  
        const handleMouseMove = (e) => {
          const deltaX = e.clientX - startX;
          const newThumbPosition = thumbPosition + deltaX;
  
          const boundedPosition = Math.max(
            0,
            Math.min(maxThumbPosition, newThumbPosition)
          );
          const scrollPosition =
            (boundedPosition / maxThumbPosition) * maxScrollLeft;
  
          scrollbarThumb.style.left = `${boundedPosition}px`;
          imageList.scrollLeft = scrollPosition;
        };
  
        const handleMouseUp = () => {
          document.removeEventListener("mousemove", handleMouseMove);
          document.removeEventListener("mouseup", handleMouseUp);
        };
  
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
      });
  
      slideButtons.forEach((button) => {
        button.addEventListener("click", () => {
          const direction = button.id === "prev-slide-op" ? -1 : 1;
          const scrollAmount = imageList.clientWidth * direction;
          imageList.scrollBy({ left: scrollAmount, behavior: "smooth" });
          updateIndicators();
        });
      });
  
      const handleSlideButtons = () => {
        slideButtons[0].style.display =
          imageList.scrollLeft <= 0 ? "none" : "flex";
        slideButtons[1].style.display =
          imageList.scrollLeft >= maxScrollLeft ? "none" : "flex";
      };
  
      const updateScrollThumbPosition = () => {
        const scrollPosition = imageList.scrollLeft;
        const thumbPosition =
          (scrollPosition / maxScrollLeft) *
          (sliderScrollbar.clientWidth - scrollbarThumb.offsetWidth);
        scrollbarThumb.style.left = `${thumbPosition}px`;
      };
  
      const updateIndicators = () => {
        const currentSlide = Math.round(
          imageList.scrollLeft / imageList.clientWidth
        );
        indicators.forEach((indicator, index) => {
          indicator.classList.toggle("active", index === currentSlide);
        });
      };
  
      imageList.addEventListener("scroll", () => {
        updateScrollThumbPosition();
        handleSlideButtons();
        updateIndicators();
      });
  
      indicators.forEach((indicator, index) => {
        indicator.addEventListener("click", () => {
          const scrollPosition = index * imageList.clientWidth;
          imageList.scrollTo({ left: scrollPosition, behavior: "smooth" });
        });
      });
  
      updateScrollThumbPosition();
      handleSlideButtons();
      updateIndicators();
    }
  };
  
  window.addEventListener("resize", initSliderOpinions);
  window.addEventListener("load", initSliderOpinions);
  