const linkFallBack =
  "/wp-content/uploads/2025/02/Guia-de-iniciacion-al-camino-de-Santiago.pdf";
const pdfUrl =
  "https://cdn.pilgrim.es/resources/Guia-de-iniciacion-al-camino-de-Santiago.pdf";
  
const fileName = "Guia-de-iniciacion-al-camino-de-Santiago.pdf";

document
  .getElementById("BtnGuideDownload")
  ?.addEventListener("click", async function () {
    try {
      // Intenta descargar desde la URL del CDN
      const response = await fetch(pdfUrl, { method: "GET" });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const blob = await response.blob();

      // Descarga el archivo
      const link = document.createElement("a");
      const objectURL = URL.createObjectURL(blob);
      link.href = objectURL;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectURL);
    } catch (error) {
      console.warn(
        "Error al descargar, intentando con la URL de respaldo:",
        error
      );

      try {
        // Intenta descargar desde la URL de respaldo
        const fallbackResponse = await fetch(linkFallBack, { method: "GET" });
        if (!fallbackResponse.ok) {
          throw new Error(`HTTP error! Status: ${fallbackResponse.status}`);
        }
        const fallbackBlob = await fallbackResponse.blob();

        // Descarga el archivo desde la URL de respaldo
        const fallbackLink = document.createElement("a");
        const fallbackObjectURL = URL.createObjectURL(fallbackBlob);
        fallbackLink.href = fallbackObjectURL;
        fallbackLink.download = fileName;
        document.body.appendChild(fallbackLink);
        fallbackLink.click();
        document.body.removeChild(fallbackLink);
        URL.revokeObjectURL(fallbackObjectURL);
      } catch (fallbackError) {
        console.warn(
          "Error al descargar desde la URL de respaldo, abriendo en nueva pestaña:",
          fallbackError
        );

        // Si falla, abre la URL del CDN en una nueva pestaña
        window.open(pdfUrl, "_blank");
      }
    } finally {
      // Redirige a la página de agradecimiento
      setTimeout(() => {
        window.location.href = "/gracias-por-su-descarga";
      }, 500);
    }
  });
