/**
 * Slider para ver los caminos que hay dentro de la navegación final de la home
 */

if (document.getElementById("sliderWhatWay")) {
    document.addEventListener("DOMContentLoaded", function () {
      const slides = document.querySelectorAll("#sliderWhatWay > div");
      const sliderContent = document.getElementById("sliderWhatWay");
      const prevButtonWhatWay = document.getElementById("prevWhatWay");
      const nextButtonWhatWay = document.getElementById("nextWhatWay");
      let currentIndexWhatWay = 0;

      function updateSliderButtons() {
        if (currentIndexWhatWay === 0) {
          prevButtonWhatWay.classList.add("p-opacity-50");
          prevButtonWhatWay.classList.add("p-pointer-events-none");
        } else {
          prevButtonWhatWay.classList.remove("p-opacity-50");
          prevButtonWhatWay.classList.remove("p-pointer-events-none");
        }

        if (currentIndexWhatWay === slides.length - 1) {
          nextButtonWhatWay.classList.add("p-opacity-50");
          nextButtonWhatWay.classList.add("p-pointer-events-none");
        } else {
          nextButtonWhatWay.classList.remove("p-opacity-50");
          nextButtonWhatWay.classList.remove("p-pointer-events-none");
        }
      }

      function updateSlider(direction) {
        if (direction === "next" && currentIndexWhatWay < slides.length - 1) {
          currentIndexWhatWay++;
        } else if (direction === "prev" && currentIndexWhatWay > 0) {
          currentIndexWhatWay--;
        }

        const offset = -currentIndexWhatWay * 100;
        sliderContent.style.transform = `translateX(${offset}%)`;
        updateSliderButtons();
      }

      updateSliderButtons();

      nextButtonWhatWay.addEventListener("click", () => updateSlider("next"));
      prevButtonWhatWay.addEventListener("click", () => updateSlider("prev"));
    });
  }
