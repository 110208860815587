document.querySelectorAll(".accordion")?.forEach((accordionContainer) => {
  const tabAccordion =
    accordionContainer.querySelectorAll(".nav-tab-accordion");
  const accordionContent = accordionContainer.querySelectorAll(
    ".accordion__accordion-content"
  );

  tabAccordion.forEach((tab, index) => {
    tab.addEventListener("click", () => {
      accordionContent.forEach((section) => section.classList.add("inactive"));
      tabAccordion.forEach((t) => t.classList.remove("active"));

      accordionContent[index].classList.remove("inactive");
      tab.classList.add("active");
    });
  });
});
