jQuery(document).ready(function ($) {
  // Obtener el filtro actual de la URL
  const urlParams = new URLSearchParams(window.location.search);
  const currentFilter = urlParams.get("cat") || "";
  const currentOrder = urlParams.get("order") || "DESC";

  // Evento para cargar más publicaciones
  $("#see-more")
    .off("click")
    .on("click", function () {
      const button = $(this);
      // Almacenar el texto original del botón
      button.data("orig-text", button.text());
      // Almacenar texto original de "no hay más artículos"
      let noMoreArticles = $("#no-more-articles").val();
      // Almacenar texto original de error
      let errorArticles = $("#error-articles").val();
      // Almacenar texto original de "cargando"
      let loading = $("#loading").val();
      const page = button.data("page") || 1; // Obtener la página actual

      $.ajax({
        url: ajaxurl, // URL global de WordPress
        method: "GET",
        data: {
          action: "load_more_posts", // Acción en el backend
          page: page,
          cat: currentFilter, // Enviar el filtro actual
          order: currentOrder,
        },
        beforeSend: function () {
          // Mostrar spinner en lugar de texto de carga
          button.html(
            `${
              "<p>" + loading + "..." + "</p>"
            }<span class="p-spinner--sm"></span>`
          );
        },
        success: function (response) {
          if (response) {
            $("#post-list").append(response); // Agregar las publicaciones nuevas
            button.data("page", page + 1); // Incrementar la página
            // Restaurar el texto original del botón
            button.html(button.data("orig-text"));
          } else {
            button.text(noMoreArticles).prop("disabled", true);
          }
        },
        error: function () {
          button.text(errorArticles);
        },
      });
    });
});
