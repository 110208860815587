document.addEventListener('DOMContentLoaded', function () {
    const btnSidebar = document.getElementById('planificador-sidebar');

    if (!btnSidebar) return;

    btnSidebar.addEventListener('click', function (e) {
      e.preventDefault();

      const seccion = document.querySelector('.p-sidebar-searcher__action');

      ['pointerdown', 'mousedown', 'mouseup', 'click'].forEach(eventType => {
        const evt = new MouseEvent(eventType, {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        seccion.dispatchEvent(evt);
      });
    });
  });