document.addEventListener("DOMContentLoaded", function () {
  let elements = document.querySelectorAll("[data-menu-experience]");
  if (elements.length > 0) {
    for (let i = 0; i < elements.length; i++) {
      let main = elements[i];

      main.addEventListener("click", function () {
        let element = main.parentElement.parentElement;
        let indicators = main.querySelectorAll("img");
        let child = element.querySelector("#menu");
        let h = element.querySelector("#mainHeading>div>div>p");

        h.classList.toggle("p-font-semibold");
        child.classList.toggle("p-hidden");
        child.classList.toggle("p-block");
        indicators[0].classList.toggle("p-rotate-180");
      });
    }

    // Objeto que almacena los valores de scrollY para cada template
    const scrollThresholds = {
      scrollY23: 2300,
      scrollY25: 2500,
      scrollY26: 2600,
      scrollY28: 2800,
      scrollY35: 3500,
      // Añade más templates y sus valores de scroll aquí
    };

    // Buscar el elemento en el documento que tiene el ID específico de scroll
    let scrollThreshold = 2500; // Valor por defecto en caso de no encontrar ningún ID

    for (let id in scrollThresholds) {
      if (document.getElementById(id)) {
        scrollThreshold = scrollThresholds[id];
        break;
      }
    }

    // Añadir el listener de scroll
    window.addEventListener("scroll", function () {
      let myDiv = document.getElementById("cardBuy");
      if (window.scrollY >= scrollThreshold) {
        myDiv.classList.remove("p-fixed");
        myDiv.classList.add("p-absolute", "p-bottom-0", "lg:p-mr-0");
      } else {
        myDiv.classList.remove("p-absolute", "p-bottom-0", "lg:p-mr-0");
        myDiv.classList.add("p-fixed");
      }
    });
  }
});
