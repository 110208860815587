/**
 * WEBPHONE SCRIPT
 */
!function() {
    var scriptwph = document.createElement("script");
    scriptwph.type = "text/javascript";
    scriptwph.src = "//llamamegratis.es/pilgrim/js/script.js";
    ( document.getElementsByTagName("head")[0] || document.documentElement ).appendChild( scriptwph )
}();

/**
 *  TRIGGER MODAL C2C
 *
 * Al llamar a una funcion no existente que nos trae un script foraneo,
 * deshabilitamos eslint para que no de call to undefinited function
 */
!function () {

    $('[data-c2c="popup"]').on('click', function(){

        /*eslint-disable */
        directLateralPopup();
        /*eslint-enable */

    })

}();
