const tabsContentInfo = document.querySelectorAll(".nav-link-info");
const contentSectionsInfo = document.querySelectorAll(".content-section-info");
const differentWaysTab = document.querySelectorAll(".nav-different-ways-info");
const differentWaysContent = document.querySelectorAll(".way-section-info");
const contentImages = document.querySelectorAll(".content-section-img");

if (tabsContentInfo.length > 0) {
  tabsContentInfo.forEach((tab, index) => {
    tab.addEventListener("click", () => {
      contentSectionsInfo.forEach((section) => section.classList.add("p-hidden"));
      contentImages.forEach((img) => img.classList.add("p-hidden"));
      tabsContentInfo.forEach((t) => t.classList.remove("active"));

      contentSectionsInfo[index].classList.remove("p-hidden");

      if (contentImages[index]) {
        contentImages[index].classList.remove("p-hidden");
      }

      tab.classList.add("active");
    });
  });
}

if (differentWaysTab.length > 0) {
  differentWaysTab.forEach((tab, index) => {
    tab.addEventListener("click", () => {
      differentWaysContent.forEach((section) => {
        section.classList.add("container-stages-hidden");
        section.classList.remove("container-stages");
      });

      differentWaysTab.forEach((t) => t.classList.remove("active"));

      differentWaysContent[index].classList.add("container-stages");
      differentWaysContent[index].classList.remove("container-stages-hidden");

      tab.classList.add("active");
    });
  });
}