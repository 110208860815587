const stageTabs = document.querySelectorAll("#tab-navigation-section a");

if (stageTabs) {
  stageTabs.forEach((tab) => {
    tab.addEventListener("click", function (event) {
      stageTabs.forEach((t) => t.classList.remove("active"));

      tab.classList.add("active");
    });
  });
}
