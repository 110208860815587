/**
 * Modal para mostrar info
 */

if (document.querySelector("#modal-history")) {
  document.addEventListener("DOMContentLoaded", function () {
    const modal = document.querySelector("#modal-history");
    const modalTitleSub = document.getElementById("modal-title-sub");
    const modalContent = document.getElementById("modal-content");
    const modalLink = document.getElementById("modal-link");
    const buttonClose = document.querySelectorAll('[data-dismiss="modal"]');

    function showModal(modal, title, contentArray, link) {
     
      if (!title || !contentArray || !link) {
        console.error(
          "Datos incompletos",
        );
        return;
      }

      modalTitleSub.textContent = title;
      modalContent.innerHTML = "";

      contentArray.forEach((paragraph) => {
        const p = document.createElement("p");
        p.textContent = paragraph;
        modalContent.appendChild(p);
      });

      modalLink.href = link;

      modal.style.display = "flex";
      document.body.style.overflow = "hidden";

      setTimeout(() => {
        modal.classList.remove("p-opacity-0", "p-invisible");
        modal.classList.add("show", "p-opacity-100", "p-visible");
      }, 10);
    }

    function dismissModal(modal) {
      modal.classList.remove("show", "p-opacity-100", "p-visible");
      modal.classList.add("p-opacity-0", "p-invisible");
      setTimeout(() => {
        modal.style.display = "none";
        document.body.style.overflow = "";
      }, 300);
    }

    buttonClose.forEach((button) => {
      button.addEventListener("click", () => {
        dismissModal(modal);
      });
    });

    const modalButtons = document.querySelectorAll(
      '.cards-primary-ways__btn[data-toggle="modal-history"]'
    );

    modalButtons.forEach((button) => {
      button.addEventListener("click", (event) => {
        event.preventDefault();

        let card = button.previousElementSibling;

        while (card && !card.classList.contains("cards-primary-ways__card")) {
          card = card.previousElementSibling || card.parentElement;
        }

        if (card) {
          const title = card.getAttribute("data-title");
          const content = card.getAttribute("data-content");
          const link = card.getAttribute("data-link");

          if (title && content && link) {
            try {
              const parsedContent = JSON.parse(content);
              showModal(modal, title, parsedContent, link);
            } catch (error) {
              console.error("Error al analizar el contenido JSON:");
            }
          } else {
            console.error(
              "Datos no encontrados en la tarjeta. Verifique que `data-title`, `data-content`, y `data-link` existen."
            );
          }
        } else {
          console.error(
            "Tarjeta contenedora no encontrada. Verifique la estructura HTML."
          );
        }
      });
    });

    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape" && modal.classList.contains("show")) {
        dismissModal(modal);
      }
    });

    modal.addEventListener("click", (event) => {
      if (event.target.classList.contains("overlay-modal")) {
        dismissModal(modal);
      }
    });
  });
}
