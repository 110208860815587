const button = document.getElementById("filterBtn");
const filterText = document.getElementById("filterText");
const dropdown = document.getElementById("selector-dropdown-menu");

if (button && filterText && dropdown) {
  const links = dropdown.querySelectorAll("a[data-filter]");

  // Obtener el filtro activo desde la URL
  const urlParams = new URLSearchParams(window.location.search);
  const currentFilter = urlParams.get("filter");

  // Si hay un filtro activo, actualizar el texto del botón
  links.forEach((link) => {
    if (link.getAttribute("data-filter") === currentFilter) {
      filterText.textContent = link.getAttribute("data-translation");
    }
  });

  // Mostrar u ocultar el menú desplegable
  button.addEventListener("click", function () {
    dropdown.classList.toggle("active");
  });

  // Manejar clics en las opciones del menú desplegable
  links.forEach((link) => {
    link.addEventListener("click", function (event) {
      const selectedTranslation = this.getAttribute("data-translation");

      filterText.textContent = selectedTranslation;
      dropdown.classList.remove("active");
    });
  });

  // Cerrar el menú desplegable si se hace clic fuera de él
  window.addEventListener("click", function (event) {
    if (!button.contains(event.target) && !dropdown.contains(event.target)) {
      dropdown.classList.remove("active");
    }
  });
}
