const profileTabs = document.querySelectorAll(
  "#profileContent .nav-tab-variant"
);
const itineraryTabs = document.querySelectorAll(
  "#itineraryContent .nav-tab-variant"
);
const profileVariants = document.querySelectorAll(
  "#profileContent .content-section-variant"
);
const itineraryVariants = document.querySelectorAll(
  "#itineraryContent .content-section-variant"
);

function toggleTabProfile(index) {
  if (profileVariants.length > 0 && profileTabs.length > 0) {
    profileVariants.forEach((variant) => variant.classList.add("p-hidden"));
    profileVariants[index].classList.remove("p-hidden");
    profileTabs.forEach((tab) => {
      tab.classList.remove(
        "p-text-gray-dark",
        "p-border-b-4",
        "p-border-primary",
        "p-font-semibold"
      );
      tab.classList.add("p-text-gray-light", "p-border-b", "p-border-light");
    });
    profileTabs[index].classList.remove(
      "p-text-gray-light",
      "p-border-b",
      "p-border-light"
    );
    profileTabs[index].classList.add(
      "p-text-gray-dark",
      "p-border-b-4",
      "p-border-primary",
      "p-font-semibold"
    );
  }

  // Sincroniza la otra barra solo si existe
  if (itineraryTabs.length > 0 && itineraryVariants.length > 0) {
    toggleTabItinerary(index);
  }
}

function toggleTabItinerary(index) {
  if (itineraryVariants.length > 0 && itineraryTabs.length > 0) {
    itineraryVariants.forEach((variant) => variant.classList.add("p-hidden"));
    itineraryVariants[index].classList.remove("p-hidden");
    itineraryTabs.forEach((tab) => {
      tab.classList.remove(
        "p-text-gray-dark",
        "p-border-b-4",
        "p-border-primary",
        "p-font-semibold"
      );
      tab.classList.add("p-text-gray-light", "p-border-b", "p-border-light");
    });
    itineraryTabs[index].classList.remove(
      "p-text-gray-light",
      "p-border-b",
      "p-border-light"
    );
    itineraryTabs[index].classList.add(
      "p-text-gray-dark",
      "p-border-b-4",
      "p-border-primary",
      "p-font-semibold"
    );
  }

  // Sincroniza la otra barra solo si existe
  if (profileTabs.length > 0 && profileVariants.length > 0) {
    toggleTabProfile(index);
  }
}
profileTabs.forEach((tab, index) => {
  tab.addEventListener("click", () => {
    toggleTabProfile(index);
  });
});

itineraryTabs.forEach((tab, index) => {
  tab.addEventListener("click", () => {
    toggleTabItinerary(index);
  });
});

toggleTabItinerary(0);
toggleTabProfile(0);
