document.addEventListener("DOMContentLoaded", function () {
    const buttons = document.querySelectorAll(".filter-button");
    const modalities = document.querySelectorAll(".filter-modality");
    const cards = document.querySelectorAll(".experience-card-item");
  
    function filterCards() {
      const activeButton = document.querySelector(".filter-button.p-bg-primary");
      const activeCategory = activeButton ? activeButton.getAttribute("data-filter") : null;
  
      const activeModalities = Array.from(
        document.querySelectorAll(".filter-modality:checked")
      ).map((cb) => cb.getAttribute("data-filter"));
  
      cards.forEach((card) => {
        const cardCategory = card.getAttribute("data-category");
        const cardMode = card.getAttribute("data-mode");
  
        if (
          (activeCategory === "all" || activeCategory === cardCategory) &&
          (activeModalities.length === 0 || activeModalities.includes(cardMode))
        ) {
          card.classList.remove("p-hidden");
          card.classList.add("p-block");
        } else {
          card.classList.add("p-hidden");
          card.classList.remove("p-block");
        }
      });
    }
  
    buttons.forEach((button) => {
      button.addEventListener("click", () => {
        buttons.forEach((btn) =>
          btn.classList.remove("p-bg-primary", "p-text-white", "p-border-0")
        );
        button.classList.add("p-bg-primary", "p-text-white", "p-border-0");
        filterCards();
      });
    });
  
    modalities.forEach((modality) => {
      modality.addEventListener("change", filterCards);
    });
  
    // Initial filter to show all cards
    filterCards();
  });
  
