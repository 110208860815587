document.addEventListener("DOMContentLoaded", () => {
  const menuButton = document.querySelector(".mobile-btn__btn"); //Boton responsive dropdown
  const menu = document.querySelector(".mobile-menu"); // Menú responsive

  const dropdownToggle = document.querySelector(".current-language"); // Botón del dropdown
  const dropdownMenu = document.querySelector(".menu-dropdown--languages"); // Menú del dropdown

  const responsiveDropdownBtn = document.querySelector(
    ".mobile-menu .dropdown-item a"
  );
  const responsiveDropdownMenu = document.querySelector(
    ".mobile-menu .dropdown-item ul"
  );

  let inputSearch = document.querySelector(".input-search");
  let btnSearch = document.querySelector(".btn-search");
  let formSearch = document.querySelector(".form-search");
  let btnSearchSpan = document.querySelector(".search-bar span button");

  if (menu && menuButton) {
    menuButton.addEventListener("click", () => {
      menu.classList.toggle("open");
      menuButton.classList.toggle("active");
    });

    document.addEventListener("click", function (event) {
      if (!menu.contains(event.target) && !menuButton.contains(event.target)) {
        menu.classList.remove("open");
        menuButton.classList.remove("active");
      }
    });
  }

  if (dropdownToggle && dropdownMenu) {
    dropdownToggle.addEventListener("click", function (event) {
      event.preventDefault();
      dropdownMenu.classList.toggle("dropdown-open");
    });

    document.addEventListener("click", function (event) {
      if (
        !dropdownToggle.contains(event.target) &&
        !dropdownMenu.contains(event.target)
      ) {
        dropdownMenu.classList.remove("dropdown-open");
      }
    });
  }

  if (responsiveDropdownMenu && responsiveDropdownBtn) {
    let clickedOnce = false;

    responsiveDropdownBtn.addEventListener("click", function (event) {
      event.preventDefault();

      if (!clickedOnce) {
        responsiveDropdownMenu.classList.toggle("open");
        clickedOnce = true;
      } else {
        window.location.href = responsiveDropdownBtn.href;
      }
    });

    document.addEventListener("click", function (event) {
      if (
        !responsiveDropdownBtn.contains(event.target) &&
        !responsiveDropdownMenu.contains(event.target)
      ) {
        responsiveDropdownMenu.classList.remove("open");
        clickedOnce = false;
      }
    });
  }

  if (inputSearch && btnSearch && formSearch && btnSearchSpan) {
    let handleSearchClick = (event) => {
      event.stopPropagation();

      if (!inputSearch.classList.contains("open-searchBar")) {
        inputSearch.classList.add("open-searchBar");
        formSearch.classList.add("open-searchBar");
        btnSearchSpan.classList.add("open-searchBar");
        btnSearch.classList.add("open-searchBar");

        inputSearch.addEventListener(
          "transitionend",
          () => {
            inputSearch.focus();
          },
          { once: true }
        );
        setTimeout(() => inputSearch.focus(), 800);
      } else {
        if (inputSearch.value.trim() !== "") {
          formSearch.submit();
        }
      }
    };

    btnSearch.addEventListener("click", handleSearchClick);
    btnSearchSpan.addEventListener("click", handleSearchClick);

    document.addEventListener("click", (event) => {
      if (formSearch && !formSearch.contains(event.target)) {
        inputSearch.classList.remove("open-searchBar");
        formSearch.classList.remove("open-searchBar");
        btnSearchSpan.classList.remove("open-searchBar");
        btnSearch.classList.remove("open-searchBar");
      }
    });
  }
});
