document.addEventListener("DOMContentLoaded", function () {
  let urlActual = window.location.pathname;
  let partesUrl = urlActual.split("/");

  let stageName = null;
  let numeroEtapa = null;

  let url = partesUrl[partesUrl.length - 2];
  let urlSplit = url.split("-");
  stageName = urlSplit[0];
  numeroEtapa = urlSplit[1];

  if (numeroEtapa && stageName) {
      let etapasContainer = document.querySelector(".stage-list");
      let etapas = Array.from(document.querySelectorAll(".stage-list__stage-card"));

      let etapaActual = etapas.find((e) =>
          e.textContent.includes(
              `${stageName.charAt(0).toUpperCase()}${stageName.slice(1)} ${numeroEtapa}`
          )
      );

      // Verificar si la etapa actual es válida y está fuera de la vista
      if (etapaActual) {
          const elementoPosicion = etapaActual.getBoundingClientRect();
          const contenedorPosicion = etapasContainer.getBoundingClientRect();

          // Verificar si la etapa está visible en el contenedor
          const isVisible =
              elementoPosicion.right <= contenedorPosicion.right &&
              elementoPosicion.left >= contenedorPosicion.left;

          // Hacer scroll si la etapa no es visible
          if (!isVisible) {
              etapasContainer.scroll({
                  left: etapaActual.offsetLeft - (contenedorPosicion.width / 2) + (elementoPosicion.width / 2),
                  behavior: "smooth",
              });
          }
      }
  }
});
