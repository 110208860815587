jQuery(document).ready(function ($) {
    // Obtener el filtro actual de la URL
    const urlParams = new URLSearchParams(window.location.search);
    const currentFilter = urlParams.get('cat') || '';
    const currentOrder = urlParams.get('order') || 'DESC';

    // Evento para cargar más publicaciones
    $('#see-more').off('click').on('click', function () {
        const button = $(this);
        const page = button.data('page') || 1; // Obtener la página actual

        $.ajax({
            url: ajaxurl, // URL global de WordPress
            method: 'GET',
            data: {
                action: 'load_more_posts', // Acción en el backend
                page: page,
                cat: currentFilter, // Enviar el filtro actual
                order: currentOrder,
            },
            beforeSend: function () {
                button.text('Cargando...'); // Mostrar mensaje de carga
            },
            success: function (response) {
                if (response) {
                    $('#post-list').append(response); // Agregar las publicaciones nuevas
                    button.data('page', page + 1).text('Cargar más artículos'); // Incrementar la página
                } else {
                    button.text('No hay más artículos').prop('disabled', true); // Deshabilitar el botón
                }
            },
            error: function () {
                button.text('Error al cargar. Intenta nuevamente'); // Mostrar mensaje de error
            }
        });
    });
});
