// Aquí va el código JavaScript que maneja el slider
const initSlider = () => {
  const imageList = document.querySelector(".slider-wrapper .card-list");
  const slideButtons = document.querySelectorAll(
    ".slider-wrapper .slide-button"
  );
  const sliderScrollbar = document.querySelector(
    ".container-slider .slider-scrollbar"
  );
  const indicators = document.querySelectorAll(".slider-indicators .indicator");

  if (sliderScrollbar && imageList && slideButtons) {
    const scrollbarThumb =
      sliderScrollbar.getElementsByClassName("scrollbar-thumb");
    const maxScrollLeft = imageList.scrollWidth - imageList.clientWidth;

    // manejar scroll
    scrollbarThumb.addEventListener("mousedown", (e) => {
      const startX = e.clientX;
      const thumbPosition = scrollbarThumb.offsetLeft;
      const maxThumbPosition =
        sliderScrollbar.getBoundingClientRect().width -
        scrollbarThumb.offsetWidth;

      // actualizar posición con movimiento de ratón
      const handleMouseMove = (e) => {
        const deltaX = e.clientX - startX;
        const newThumbPosition = thumbPosition + deltaX;

        // para que la scrollbar no se salga
        const boundedPosition = Math.max(
          0,
          Math.min(maxThumbPosition, newThumbPosition)
        );
        const scrollPosition =
          (boundedPosition / maxThumbPosition) * maxScrollLeft;

        scrollbarThumb.style.left = `${boundedPosition}px`;
        imageList.scrollLeft = scrollPosition;
      };

      // quitar escuchadores en mouse up
      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      // añadir escuchadores para arrastre
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    });

    // mover imágenes según los clicks al botón del slide
    slideButtons.forEach((button) => {
      button.addEventListener("click", () => {
        const direction = button.id === "prev-slide" ? -1 : 1;
        const scrollAmount = imageList.clientWidth * direction;
        imageList.scrollBy({ left: scrollAmount, behavior: "smooth" });
        updateIndicators();
      });
    });

    // mostrar o no los botones del slide según posición
    const handleSlideButtons = () => {
      slideButtons[0].style.display =
        imageList.scrollLeft <= 0 ? "none" : "flex";
      slideButtons[1].style.display =
        imageList.scrollLeft >= maxScrollLeft ? "none" : "flex";
    };

    // actualizar posición de la scrollbar según posición
    const updateScrollThumbPosition = () => {
      const scrollPosition = imageList.scrollLeft;
      const thumbPosition =
        (scrollPosition / maxScrollLeft) *
        (sliderScrollbar.clientWidth - scrollbarThumb.offsetWidth);
      scrollbarThumb.style.left = `${thumbPosition}px`;
    };

    // actualizar indicadores según posición
    const updateIndicators = () => {
      const currentSlide = Math.round(
        imageList.scrollLeft / imageList.clientWidth
      );
      indicators.forEach((indicator, index) => {
        indicator.classList.toggle("active", index === currentSlide);
      });
    };

    // llamar funciones al hacer scroll en la lista
    imageList.addEventListener("scroll", () => {
      updateScrollThumbPosition();
      handleSlideButtons();
      updateIndicators();
    });

    // añadir eventos de click en los indicadores
    indicators.forEach((indicator, index) => {
      indicator.addEventListener("click", () => {
        const scrollPosition = index * imageList.clientWidth;
        imageList.scrollTo({ left: scrollPosition, behavior: "smooth" });
      });
    });

    // configuración inicial
    updateScrollThumbPosition();
    handleSlideButtons();
    updateIndicators();
  }
};

window.addEventListener("resize", initSlider);
window.addEventListener("load", initSlider);
