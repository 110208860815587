/**
 * Slider para tarjetas de experiencia
 */

if (document.getElementById("experiencesCarousel")) {
  document.addEventListener("DOMContentLoaded", function () {
    const slideContainer = document.querySelector(".card-list");
    const prevSlideBtn = document.getElementById("prev-slide-experiences");
    const nextSlideBtn = document.getElementById("next-slide-experiences");
    const indicatorsContainer = document.getElementById("indicators");

    const cards = Array.from(document.querySelectorAll(".card-item"));
    const cardsPerSlide = 3; // Número de tarjetas visibles por diapositiva
    let currentSlide = 0;
    const totalSlides = Math.ceil(cards.length / cardsPerSlide);

    // Calcular el ancho de una tarjeta, incluyendo el gap
    function getSlideWidth() {
      const containerWidth = slideContainer.parentElement.offsetWidth;
      const gapWidth = parseFloat(getComputedStyle(slideContainer).gap) || 0;
      return containerWidth + gapWidth; // Ancho total con gap incluido
    }

    // Actualizar visibilidad de botones y estado de indicadores
    function updateNavigation() {
      prevSlideBtn.classList.toggle("p-hidden", currentSlide === 0);
      nextSlideBtn.classList.toggle(
        "p-hidden",
        currentSlide >= totalSlides - 1
      );

      indicatorsContainer.innerHTML = "";
      for (let i = 0; i < totalSlides; i++) {
        const indicator = document.createElement("span");
        indicator.classList.add(
          "indicator",
          "p-w-2",
          "p-h-2",
          "p-bg-gray-border",
          "p-rounded-full",
          "p-cursor-pointer"
        );
        if (i === currentSlide)
          indicator.classList.add("active", "p-bg-primary");
        indicatorsContainer.appendChild(indicator);
        indicator.addEventListener("click", () => {
          currentSlide = i;
          updateSlidePosition();
          updateNavigation();
        });
      }
    }

    // Mueve las tarjetas en el contenedor
    function updateSlidePosition() {
      const slideWidth = getSlideWidth();
      slideContainer.style.transform = `translateX(-${
        currentSlide * slideWidth
      }px)`;
    }

    // Eventos de botones
    prevSlideBtn.addEventListener("click", function () {
      if (currentSlide > 0) {
        currentSlide--;
        updateSlidePosition();
        updateNavigation();
      }
    });

    nextSlideBtn.addEventListener("click", function () {
      if (currentSlide < totalSlides - 1) {
        currentSlide++;
        updateSlidePosition();
        updateNavigation();
      }
    });

    // Inicialización
    function initializeSlider() {
      updateNavigation();
      updateSlidePosition();
    }

    initializeSlider();
    window.addEventListener("resize", updateSlidePosition);
  });
}
