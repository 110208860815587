const darkBackgroundImage = document.querySelectorAll("[data-background]");

darkBackgroundImage.forEach((b) => {
  const darkBackgroundImageUrl = b.getAttribute("data-background");

  if (darkBackgroundImageUrl) {
    b.style.backgroundImage = `
      linear-gradient(
        to right bottom,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)
      ),
      url(${darkBackgroundImageUrl})
    `;
  }
});
