/**
 * Get platform based on width
 * @param {int} size of element
 * @return {string} web or responsive
 */
window.isWebOrResponsive = function (size = 992) {
    return (window.innerWidth > size) ? 'web' : 'responsive';
}

/**
 * Get planner based on class exist
 * @return {string}
 */
window.plannerDetectedOnPage = function () {
    if (document.querySelector(".form-planner")) return 'yes_form';
    if (document.querySelector(".planner--sidebar")) return 'yes_sidebar';
    return 'no';
}
