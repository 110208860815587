import "../helpers/gtm";

export default {
  init() {
    // JavaScript to be fired on all pages
    this.header.init();
    this.select_map_home.init();
    this.experience_service.init();
    this.experience_single_page.init();
    this.service_single_page.init();
    this.interest_point.init();
    this.services_general_page.init();
    this.selector_lang.init();
    this.check_slides_santiago.init();
    this.menu_search.init();
    this.accordion_landing_xacobeo.init();
    this.popup_language.init();
  },

  /**
   * Header
   */
  header: {
    init() {
      this.scroll.init();
    },

    /**
     * Oculta banner header
     */
    scroll: {
      init() {
        $(window).on("load", () => {
          // Configura el evento scroll una sola vez
          $(window).on("scroll", this.handleScroll);

          // Configura el botón flotante
          $(".planner--button--fixed").show();
          $(".planner--button--fixed").css("top", $("header").height());
        });
      },

      handleScroll() {
        // Usa un debounce para mejorar rendimiento
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
          if ($(window).scrollTop() > 150) {
            $("#infoBar").slideUp("fast");
          } else {
            $("#infoBar").slideDown("fast");
          }
        }, 200);
      },
    },
  },

  select_map_home: {
    init() {
      this.select_way();
    },

    /**
     * Comprobar carga inicio en home
     */
    select_way() {
      $(document).ready(function () {
        $(".select_way_description").hide();
        $(".map-home-select").hide();
        $(
          "#way_description_" + $("#select_way_home option:selected").val()
        ).show();
        $("#map_way_" + $("#select_way_home option:selected").val()).show();
      });
      $("#select_way_home").change(function () {
        $(".select_way_description").hide();
        $(".map-home-select").hide();
        $(
          "#way_description_" + $("#select_way_home option:selected").val()
        ).show();
        $("#map_way_" + $("#select_way_home option:selected").val()).show();
      });
    },
  },

  experience_service: {
    init() {
      this.view_experience_service();
    },

    /**
     * Efecto scroll pagina Experiencia Individual
     */

    view_experience_service() {
      $(document).ready(function () {
        $(".product-card .product-button").hide();
        $(".product-card .product-button .btn").hide();

        $(".product-card").hover(
          function () {
            $(this)
              .find(".product-img")
              .show()
              .animate({ height: "94px" }, "fast");
            $(this)
              .find(".product-img .product-img-content")
              .show()
              .animate({ height: "94px" }, "fast");
            $(this)
              .find(".product-button")
              .show()
              .animate({ height: "67px" }, "fast");
            $(this).find(".product-button .btn").css("display", "inline-block");
          },
          function () {
            $(this)
              .find(".product-img")
              .show()
              .animate({ height: "160px" }, "fast");
            $(this)
              .find(".product-img .product-img-content")
              .show()
              .animate({ height: "160px" }, "fast");
            $(this)
              .find(".product-button")
              .show()
              .animate({ height: "0px" }, "fast");
            $(this).find(".product-button .btn").css("display", "none");
          }
        );
      });
    },
  },

  //TO DO -> EFECTO DESPLEGAR TAB EXPERIENCIAS INDIVIDUAL, deber ser lanzado solo en páginas de servicio individual
  experience_single_page: {
    init() {
      this.view_experience_single_page();
    },

    view_experience_single_page() {
      $(document).ready(function () {
        $("#single-experience-tab .nav-link").on("click", function () {
          $("html, body").scrollTop(
            $("#single-experience-tab-content").offset().top - 150
          );
        });
      });
    },
  },

  //TO DO -> EFECTO DESPLEGAR TAB EXPERIENCIAS INDIVIDUAL, deber ser lanzado solo en páginas de experiencia individual
  service_single_page: {
    init() {
      this.view_service_single_page();
    },

    view_service_single_page() {
      $(document).ready(function () {
        $("#single-service-tab .nav-link").on("click", function () {
          $("html, body").scrollTop(
            $("#single-service-tab-content").offset().top - 150
          );
        });
      });
    },
  },

  //TO DO -> EFECTO DESPLEGAR TAB PUNTOS INTERES, deber ser lanzado solo en páginas de punto de interes
  interest_point: {
    init() {
      this.view_interest_point();
    },

    view_interest_point() {
      $(document).ready(function () {
        $(".single-experience-tab-links .nav-link").on("click", function () {
          var current_link = $(this);
          $("html, body").scrollTop(
            current_link
              .parent()
              .parent()
              .parent()
              .find(".interest-point-tab-content-parent")
              .offset().top - 150
          );
        });
      });
    },
  },

  //TO DO -> EFECTO MOSTRAR BOTON AUTOMATICAMENTE CAROUSEL SERVICIOS, DEBER SER OPTIMIZADO EL CÓDIGO
  services_general_page: {
    init() {
      this.view_services_general_page();
    },

    view_services_general_page() {
      $(document).ready(function () {
        //DESHABILITAR EFECTO HOVER CARD -  MOSTRAR BOTONES POR DEFECTO
        var exist_carousel =
          $(".services-cards-carousel").length > 0 ? true : false;
        if (exist_carousel) {
          let prod_card = $(".services-cards-carousel .product-card");
          prod_card.unbind("hover");
          prod_card
            .find(".product-img")
            .show()
            .animate({ height: "94px" }, 400);
          prod_card
            .find(".product-img .product-img-content")
            .show()
            .animate({ height: "94px" }, 400);
          prod_card
            .find(".product-button")
            .show()
            .animate({ height: "67px" }, 400);
          prod_card.find(".product-button .btn").css("display", "inline-block");
        }
      });
    },
  },

  // Funcionaliti for language selector
  selector_lang: {
    init() {
      this.view_selector_lang();
    },

    view_selector_lang() {
      $("[data-toggle='toggle']").click(function () {
        var selector = $(this).data("target");
        $(selector).toggleClass("in");
      });
    },
  },

  check_slides_santiago: {
    init() {
      this.check_gastronomia();
      this.check_puntos();
    },

    check_gastronomia() {
      $("#carousel-point-interest").bind("slid.bs.carousel", function () {
        $("#carousel-point-interest li").removeClass("active");
        $(
          "#carousel-point-interest li[data-slide-to = " +
            $("#carousel-point-interest .carousel-item.active").index() +
            "]"
        ).addClass("active");
      });
    },

    check_puntos() {
      $("#carousel-gastronomia").bind("slid.bs.carousel", function () {
        $("#carousel-gastronomia li").removeClass("active");
        $(
          "#carousel-gastronomia li[data-slide-to = " +
            $("#carousel-gastronomia .carousel-item.active").index() +
            "]"
        ).addClass("active");
      });
    },
  },

  // Functionality for menu SEARCH FORM
  menu_search: {
    init() {
      this.view_menu_search();
    },

    view_menu_search() {
      $('.search-btn_desktop[data-toggle="collapse"]').on("click", function () {
        $("#search_form_desktop").toggleClass("position-absolute");
        $(".header .navbar-nav.navbar-right").toggleClass("d-none");
        $(this).find(".fas").toggleClass("fa-search").toggleClass("fa-times");
      });
    },
  },

  /**
   *
   */

  // Functionality for menu SEARCH FORM
  accordion_landing_xacobeo: {
    init() {
      this.view_accordion_landing_xacobeo();
    },

    view_accordion_landing_xacobeo() {
      $("#accordionWalks .collapse").on("show.bs.collapse", function (e) {
        $(`i[data-target="#${e.currentTarget.id}"]`)
          .removeClass("pilgrim-plus-circle")
          .addClass("pilgrim-less-circle");
      });
      $("#accordionWalks .collapse").on("hide.bs.collapse", function (e) {
        $(`i[data-target="#${e.currentTarget.id}"]`)
          .removeClass("pilgrim-less-circle")
          .addClass("pilgrim-plus-circle");
      });
    },
  },

  popup_language: {
    init() {
      $(window).load(() => {
        //$('#ModalChangeLang').modal('show');
      });
    },
  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
