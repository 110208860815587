jQuery(document).ready(function ($) {
  function updateResults(page) {
    let $currentResults = $("#search-results");

    $.ajax({
      url: ajaxurl,
      method: "POST",
      data: {
        action: "load_more_searches",
        page: page,
        query: currentQuery,
      },
      success: function (response) {
        $currentResults.fadeOut(600, function() {
          $(this).html(response.html).fadeIn(600);  
        });

        let maxPages = response.max_pages;
        $("#prev-button").attr("data-page", Math.max(1, page - 1));
        $("#next-button").attr("data-page", page + 1);
        $("#current-paged").text(page); 

        $("#prev-button").prop("disabled", page === 1).toggleClass("p-bg-secondary-light", page === 1);
        $("#next-button").prop("disabled", page >= maxPages).toggleClass("p-bg-secondary-light", page >= maxPages);
      },
      error: function () {
        alert("Hubo un error al cargar más resultados.");
      },
    });
  }

  $("#prev-button, #next-button").on("click", function () {
    let page = parseInt($(this).attr("data-page"));
    updateResults(page, $(this).attr("id") === "next-button" ? "next" : "prev");
  });
});
