if (document.getElementById("btnSeeMore")) {
    document.getElementById('btnSeeMore').addEventListener('click', function () {
        const extraContent = document.getElementById('moreContent');
        const seeLessBtn = document.getElementById('btnSeeLess');

        extraContent.classList.remove('p-hidden');
        this.classList.add('p-hidden');
        seeLessBtn.classList.remove('p-hidden');
    });

    document.getElementById('btnSeeLess').addEventListener('click', function () {
        const extraContent = document.getElementById('moreContent');
        const seeMoreBtn = document.getElementById('btnSeeMore');

        extraContent.classList.add('p-hidden');
        this.classList.add('p-hidden');
        seeMoreBtn.classList.remove('p-hidden');
    });
}

