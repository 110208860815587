document.querySelectorAll(".accordion-btn").forEach((button) => {
  button.addEventListener("click", function () {
    //Toggle hidden content
    const content = this.nextElementSibling;
    const icon = this.querySelector("span:last-child");

    // Close all open FAQs instantly
    document.querySelectorAll(".accordion-content.show").forEach((openContent) => {
      if (openContent !== content) {
        openContent.classList.remove("show");
        openContent.previousElementSibling.querySelector(
          "span:last-child"
        ).textContent = "+";
      }
    });

    // Toggle the clicked FAQ
    if (content.classList.contains("show")) {
      content.classList.remove("show");
      icon.textContent = "+";
    } else {
      content.classList.add("show");
      icon.textContent = "-";
    }
  });
});
