const navLinks = document.querySelectorAll(".nav-link-experience");

navLinks.forEach((link) => {
  link.addEventListener("click", function (event) {
    event.preventDefault();

    navLinks.forEach((link) => {
      link.classList.remove("p-border-b-4", "p-border-primary");
    });

    this.classList.add("p-border-b-4", "p-border-primary");

    const target = this.getAttribute("data-target");

    document.getElementById(target).scrollIntoView({ behavior: "smooth" });
  });
});