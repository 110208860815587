/**
 * Slider para tarjetas de opiniones
 */

if (document.getElementById("opinionsCarousel")) {
    document.addEventListener("DOMContentLoaded", function () {
      const slideContainerOpinion = document.querySelector(".card-list-opinion");
      const prevSlideBtnOpinion = document.getElementById("prev-slide-opinions");
      const nextSlideBtnOpinion = document.getElementById("next-slide-opinions");
      const indicatorsContainerOpinion = document.getElementById("indicatorsOpinions");
  
      const cardsOpinions = Array.from(document.querySelectorAll(".card-item-opinions"));
      const cardsPerSlideOpinion = 3; // Número de tarjetas visibles por diapositiva
      let currentSlideOpinion = 0;
      const totalSlidesOpinion = Math.ceil(cardsOpinions.length / cardsPerSlideOpinion);
  
      // Calcular el ancho de una tarjeta, incluyendo el gap
      function getSlideWidth() {
        const containerWidthOpinion = slideContainerOpinion.parentElement.offsetWidth;
        const gapWidthOpinion = parseFloat(getComputedStyle(slideContainerOpinion).gap) || 0;
        return containerWidthOpinion + gapWidthOpinion; // Ancho total con gap incluido
      }
  
      // Actualizar visibilidad de botones y estado de indicadores
      function updateNavigation() {
        prevSlideBtnOpinion.classList.toggle("p-hidden", currentSlideOpinion === 0);
        nextSlideBtnOpinion.classList.toggle(
          "p-hidden",
          currentSlideOpinion >= totalSlidesOpinion - 1
        );
  
        indicatorsContainerOpinion.innerHTML = "";
        for (let i = 0; i < totalSlidesOpinion; i++) {
          const indicatorOpinion = document.createElement("span");
          indicatorOpinion.classList.add(
            "indicator",
            "p-w-2",
            "p-h-2",
            "p-bg-gray-border",
            "p-rounded-full",
            "p-cursor-pointer"
          );
          if (i === currentSlideOpinion)
            indicatorOpinion.classList.add("active", "p-bg-primary");
          indicatorsContainerOpinion.appendChild(indicatorOpinion);
          indicatorOpinion.addEventListener("click", () => {
            currentSlideOpinion = i;
            updateSlidePosition();
            updateNavigation();
          });
        }
      }
  
      // Mueve las tarjetas en el contenedor
      function updateSlidePosition() {
        const slideWidthOpinion = getSlideWidth();
        slideContainerOpinion.style.transform = `translateX(-${
            currentSlideOpinion * slideWidthOpinion
        }px)`;
      }
  
      // Eventos de botones
      prevSlideBtnOpinion.addEventListener("click", function () {
        if (currentSlideOpinion > 0) {
          currentSlideOpinion--;
          updateSlidePosition();
          updateNavigation();
        }
      });
  
      nextSlideBtnOpinion.addEventListener("click", function () {
        if (currentSlideOpinion < totalSlidesOpinion - 1) {
          currentSlideOpinion++;
          updateSlidePosition();
          updateNavigation();
        }
      });
  
      // Inicialización
      function initializeSlider() {
        updateNavigation();
        updateSlidePosition();
      }
  
      initializeSlider();
      window.addEventListener("resize", updateSlidePosition);
    });
  }
  